<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12">
				<p class="heading-sf20">Preferences</p>
			</v-col>
		</v-row>

		<v-row no-gutters dense class="flex-grow-0">
			<v-col cols="12" class="pt-0">
				<v-switch v-model="bid_email" @change="changePreference()" color="#2969E7" label="Quote Email"></v-switch>
			</v-col>

			<v-col cols="12" class="pt-0">
				<v-switch v-model="list_email" @change="changePreference()" color="#2969E7" label="List Email"></v-switch>
			</v-col>

			<v-col cols="12" class="pt-0">
				<v-switch v-model="delivery_email" @change="changePreference()" color="#2969E7" label="Delivery Email"></v-switch>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { preference } from '../../services/authservices'
export default {
	data() {
		return {
			active_tab: false,
			bid_email: true,
			list_email: true,
			delivery_email: true,
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.setting.preference, state.setting.setting_tab
			},
			() => {
				if (this.$store.state.setting.setting_tab.tab === 'pass_pref') {
					let data = this.$store.state.setting.preference
					this.bid_email = data.bid_email === 'Y' ? true : false
					this.list_email = data.list_email === 'Y' ? true : false
					this.delivery_email = data.delivery_email === 'Y' ? true : false
				}
			},
			{ deep: true }
		)
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true)
		},
	},

	methods: {
		preferenceInfo(data) {
			this.bid_email = data.bid_email === 'Y' ? true : false
			this.list_email = data.list_email === 'Y' ? true : false
			this.delivery_email = data.delivery_email === 'Y' ? true : false
		},

		async changePreference() {
			this.active_tab = true

			let preference_data = {
				bid_email: this.bid_email === true ? 'Y' : 'N',
				list_email: this.list_email === true ? 'Y' : 'N',
				delivery_email: this.delivery_email === true ? 'Y' : 'N',
			}

			let data = await preference(preference_data)
			if (data.data) {
				this.$store.commit('preferenceInfo', data.data)
				// this.preferenceInfo(data.data)
				this.active_tab = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
